import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Row } from 'react-bootstrap';
import TeamMemberCard from '../components/TeamMemberCard';
import { SPageH1Title, StyledContainer } from '../containers/Layout/styles';
import Breadcrumbs from '../components/Breadcrumbs';

const Komanda = ({ data }) => {
    return (
        <StyledContainer fluid className="overflow-hidden">
            <Breadcrumbs title="Karjera" />
            <SPageH1Title>Komanda</SPageH1Title>
            <Row className="gy-2 gy-md-4 gx-lg-4 gy-lg-5">
                {data?.allWpKomanda.nodes.map((teamMember) => {
                    const image = teamMember.featuredImage
                        ? getImage(teamMember.featuredImage.node.localFile)
                        : null;

                    return (
                        <TeamMemberCard
                            key={teamMember.id}
                            name={teamMember.title}
                            description={teamMember.komandosNariai.pareigos}
                            imgSrc={image}
                            imgAlt={teamMember.title}
                            socialMedia={[
                                teamMember.komandosNariai.linkedInUrl,
                            ]}
                        />
                    );
                })}
            </Row>
        </StyledContainer>
    );
};

export default Komanda;

export const pageQuery = graphql`
    query {
        allWpKomanda {
            nodes {
                id
                title
                komandosNariai {
                    elPastas
                    linkedInUrl
                    pareigos
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    }
`;
