import { GatsbyImage } from 'gatsby-plugin-image';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SCardWrapper = styled.div`
    padding: 32px;
    height: 100%;
    border: 1px solid ${(props) => props.theme.color.grey_900};
`;

export const SPicture = styled(GatsbyImage)`
    max-height: 300px;
    margin-bottom: 32px;
`;
export const SName = styled.h4``;
export const SDescription = styled.div`
    color: ${(props) => props.theme.color.grey_400};
    margin-bottom: 32px;
`;
export const SSocialMediaContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
`;
export const SSocialMediaItem = styled.div``;
