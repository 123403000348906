import React from 'react';
import { Col } from 'react-bootstrap';
import {
    SCardWrapper,
    SPicture,
    SName,
    SDescription,
    SSocialMediaContainer,
    SSocialMediaItem,
} from './styles';

const TeamMemberCard = ({ imgSrc, imgAlt, name, description, socialMedia, key }) => {
    return (
        <Col xs={12} md={6} lg={3} key={key}>
            <SCardWrapper>
                <SPicture image={imgSrc} alt={imgAlt} />
                <SName>{name}</SName>
                <SDescription>{description}</SDescription>
                <SSocialMediaContainer>
                    {/* {socialMedia.map((item, index) => (
                        <SSocialMediaItem key={index}>X</SSocialMediaItem>
                    ))} */}
                </SSocialMediaContainer>
            </SCardWrapper>
        </Col>
    );
};

export default TeamMemberCard;
